<template>
  <div
    :class="`${
      ($route.query.single &&
        ($route.params.view == 'boxes' || !$route.params.view)) ||
      $route.query.listing
        ? 'nav-bar-ab'
        : 'nav-bar'
    } z-100 border-bottom d-flex justify-content-around align-items-center w-100 bg-white`"
  >
    <!--   <div
    class="
      nav-bar-ab
      z-100
      border-bottom
      d-flex
      justify-content-around
      align-items-center
      w-100
    "
  > -->
    <div :class="colorClass('profile')" @click="navigateTo('profile')">
      <inline-svg
        src="/perfil.svg"
        width="30px"
        height="30px"
        fill="white"
        aria-label="Perfil"
      />
    </div>
    <div class="vl"></div>
    <div :class="colorClass('boxes')" @click="navigateTo('boxes')">
      <inline-svg
        src="/monitoreo.svg"
        width="30px"
        height="30px"
        fill="white"
        aria-label="Loading..."
      />
    </div>
    <div class="vl"></div>
    <div :class="colorClass('calendar')" @click="navigateTo('calendar')">
      <inline-svg
        src="/calendario.svg"
        width="30px"
        height="30px"
        fill="white"
        aria-label="Loading..."
      />
    </div>
    <div class="vl"></div>
    <div
      :class="colorClass('notifications')"
      @click="navigateTo('notifications')"
    >
      <inline-svg
        src="/notificaciones.svg"
        width="30px"
        height="30px"
        fill="white"
        aria-label="Loading..."
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counterAdmin: 0
    }
  },
  computed: {
    view() {
      return this.$route.params.view ? this.$route.params.view : "boxes"
    }
  },
  methods: {
    navigateTo(key) {
      if (key == "calendar") {
        this.counterAdmin = this.counterAdmin + 1

        if (this.counterAdmin >= 5) {
          this.$router.push({
            name: "Admin",
            params: { viewA: undefined },
            query: {}
          })
        } else {
          this.$router.push({
            name: "View",
            params: { view: key },
            query: {}
          })
        }
      } else {
        this.$router.push({
          name: "View",
          params: { view: key },
          query: {}
        })
      }
    },
    colorClass(key) {
      if (key == this.view) {
        return "bg-green rounded"
      } else {
        return " "
      }
    }
  }
}
</script>

<style>
.vl {
  border-left: 0.08rem solid #b7b7b7;
  height: 27px;
  max-width: 1px;
}
</style>
